<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Global Filters</h3>
      </div>
      <el-form ref="form">
        <el-row class="sidemenu">
          <el-col :span="24">
            <div class="card-body">
              <div class="p-1">
                <div class="navbar-align">
                  <h4 class="fs-6 text-dark fw-medium">Filters</h4>
                  <el-button
                    @click="addFilter"
                    icon="el-icon-plus"
                    size="mini"
                    type="primary"
                    >Add</el-button
                  >
                </div>
                <div>
                  <div v-for="(filter, i) in filters" :key="'index' + i">
                    <div class="d-flex" style="gap: 5px">
                      <div class="d-flex-col">
                        Filter type
                        <el-select
                          v-model="filter.field_type"
                          placeholder="Select field type"
                          size="mini"
                          style="width: 150px"
                        >
                          <el-option value="ENTITY" label="Entity"></el-option>
                          <!-- <el-option value="DATE" label="Date"></el-option> -->
                        </el-select>
                      </div>
                      <!-- Entity -->
                      <template
                        v-if="filter.field_type == 'ENTITY'"
                        class="ml-1"
                      >
                        <div class="d-flex-col">
                          Entity
                          <el-select
                            placeholder="Select entity"
                            v-model="filter.entity_id"
                            class="input-text"
                            @change="
                              (changedValue) =>
                                updateAndLoadMore(changedValue, filter)
                            "
                            remote
                            :remote-method="searchEntities"
                            filterable
                            loading-text="Loading..."
                            :loading="entityOptionsLoading"
                            clearable
                            size="mini"
                          >
                            <el-option
                              v-for="entity in allEntities"
                              :key="'entity' + entity._id"
                              :value="entity._id"
                              :label="entity.name"
                            >
                            </el-option>
                            <el-option
                              value="LOADMORE"
                              label="Load more..."
                              v-if="hasMoreEntities && !isEntitySearching"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="d-flex-col" style="width: 80px">
                          Operator
                          <el-select
                            placeholder="Select operator"
                            v-model="filter.operator"
                            class="input-text"
                            size="mini"
                          >
                            <el-option value="in" label="In"> </el-option>
                            <el-option value="nin" label="Not In"> </el-option>
                          </el-select>
                        </div>
                        <div class="d-flex-col" v-if="filter.entity_id">
                          Value
                          <el-select
                            placeholder="Select operator"
                            v-model="filter.value"
                            class="input-text"
                            :loading="
                              allEntityDataLoading.indexOf(filter.entity_id) !=
                              -1
                                ? true
                                : false
                            "
                            @change="
                              (changedValue) =>
                                loadMoreEntityData(changedValue, filter)
                            "
                            size="mini"
                            clearable
                          >
                            <el-option
                              v-for="(data, i) in allEntityDataObj[
                                filter.entity_id
                              ] || []"
                              :key="'entity' + data._id"
                              :value="data._id"
                              :label="
                                getLabel(
                                  allEntityDataEntity[filter.entity_id],
                                  data,
                                  i
                                )
                              "
                            >
                            </el-option>
                            <el-option
                              value="LOADMORE"
                              label="Load more..."
                              v-if="allEntityDataHasMore[filter.entity_id]"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <div class="d-flex-col" v-if="filter.entity_id">
                          Apply on
                          <el-select
                            placeholder="Select other entities"
                            v-model="filter.apply_on"
                            class="input-text"
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            loading-text="Loading..."
                            :loading="
                              otherEntitiesLoading.indexOf(filter.entity_id) !=
                              -1
                                ? true
                                : false
                            "
                            size="mini"
                          >
                            <el-option
                              v-for="field in otherEntityFields[
                                filter.entity_id
                              ]"
                              :key="'field' + field.key"
                              :value="field.key"
                              :label="field.label"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                      <!-- Entity -->
                      <!-- Date -->
                      <template v-if="filter.field_type == 'DATE'">
                        <div class="d-flex-col">
                          Filter type
                          <el-select
                            v-model="filter.filter_type"
                            placeholder="Select field type"
                            size="mini"
                          >
                            <el-option
                              value="PICKER_OPTIONS"
                              label="Picker options"
                            >
                            </el-option>
                            <el-option value="DATE_RANGE" label="Date range">
                            </el-option>
                          </el-select>
                        </div>
                        <div
                          class="d-flex-col"
                          v-if="filter.filter_type == 'PICKER_OPTIONS'"
                        >
                          Pick date range
                          <el-select
                            :placeholder="'Pick date range'"
                            v-model="filter.value"
                            :style="'width: 100%;'"
                            size="mini"
                          >
                            <el-option
                              v-for="(el, index) in pickerOptions"
                              :key="el.value + '_' + index"
                              :value="el.value"
                              :label="el.label"
                              >{{ el.label }}
                            </el-option>
                          </el-select>
                        </div>
                        <div
                          class="d-flex-col"
                          v-else-if="filter.filter_type == 'DATE_RANGE'"
                        >
                          Date range
                          <el-date-picker
                            v-model="filter.value"
                            type="daterange"
                            range-separator="To"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            size="mini"
                          >
                          </el-date-picker>
                        </div>
                      </template>
                      <!-- Date -->
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Include in topbar"
                        placement="top-start"
                      >
                        <el-checkbox
                          label=""
                          style="margin-top: 25px"
                          v-model="filter.include_in_topbar"
                          :disabled="checkAlreadyChecked(i)"
                        ></el-checkbox>
                      </el-tooltip>
                      <el-link
                        type="danger"
                        :underline="false"
                        @click="removeFilter(i)"
                      >
                        <i class="el-icon-delete" style="margin-top: 25px"></i>
                      </el-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button
              type="primary"
              @click="onSubmit"
              :disabled="checkDisabled"
              >Save</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "global-filters",
  data() {
    return {
      filters: [{}],
      loading: false,
      loadingText: "Fetching info...",
      allEntities: [],
      hasMoreEntities: false,
      entityPage: 1,
      entityLimit: 10,
      isEntitySearching: false,
      entityOptionsLoading: false,
      entitySearchString: "",
      entityLoadMore: false,
      //entity data loading
      allEntityDataObj: {},
      allEntityDataLimit: {},
      allEntityDataPage: {},
      allEntityDataLoading: [],
      allEntityDataHasMore: {},
      allEntityDataEntity: {},
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
      ],
      companyDetails: {},
      otherEntities: [],
      otherEntitiesLoading: [],
      otherEntityFields: {},
    };
  },
  methods: {
    checkAlreadyChecked(index) {
      let checked = this.filters.findIndex(
        (e, i) => e.include_in_topbar && i !== index
      );
      return checked > -1 ? true : false;
    },
    removeFilter(i) {
      this.filters.splice(i, 1);
    },
    addFilter() {
      this.filters.push({});
    },
    loadMoreEntityData(value, filter) {
      if (value == "LOADMORE") {
        filter.value = "";
        this.fetchEntityData([filter.entity_id]);
      }
    },
    getLabel(selectedEntity, item, i) {
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      if (Array.isArray(name)) {
      if (name.length === 2) {
        return `${name[0]} To ${name[1]}`; 
      }
      return name.join(', ');  
    }
    return name; 
    },
    updateAndLoadMore(value, filter) {
      if (value == "LOADMORE") {
        this.entityLoadMore = true;
        this.entityPage++;
        filter.entity_id = "";
        this.fetchAllEntityies();
      } else {
        this.fetchEntityData([value]);
        this.fetchEntityRelatedData([value]);
      }
    },
    async fetchEntityRelatedData(value) {
      if (value && value.length) {
        await value.map(async (entity) => {
          this.otherEntitiesLoading.push(entity);

          await this.$store.dispatch(
            "entities/fetchEntityFieldRelatedEntities",
            entity
          );
          if (this.getEntityFieldRelatedEntities) {
            this.otherEntityFields[entity] = [
              ...this.getEntityFieldRelatedEntities,
            ];
          }
          this.otherEntitiesLoading.splice(
            this.otherEntitiesLoading.indexOf(entity),
            1
          );
          return entity;
        });
      } else {
        this.otherEntities = [];
      }
    },
    async fetchEntityData(entityIds = []) {
      if (entityIds && entityIds.length) {
        await entityIds.map(async (entity) => {
          this.allEntityDataLoading.push(entity);
          let limit = 10;
          let page =
            this.allEntityDataPage && this.allEntityDataPage[entity]
              ? parseInt(this.allEntityDataPage[entity]) + 1
              : 1;
          let params = {
            entity_id: entity,
            template_fields_data: [],
            filters: [],
            relationship: {},
            limit: limit,
            page: page,
          };
          await this.$store.dispatch(
            "entities/fetchEntityRecordsForTable",
            params
          );
          if (this.getEntityRecordsForTable) {
            this.allEntityDataHasMore[entity] =
              this.getEntityRecordsForTable.hasMore;
            this.allEntityDataObj[entity] = [
              ...(this.allEntityDataObj[entity] || []),
              ...this.getEntityRecordsForTable.data,
            ];
            this.allEntityDataEntity[entity] =
              this.getEntityRecordsForTable.selectedEntity;
            this.allEntityDataLimit[entity] = limit;
            this.allEntityDataPage[entity] = page;
          }
          this.allEntityDataLoading.splice(
            this.allEntityDataLoading.indexOf(entity),
            1
          );
          return entity;
        });
      }
    },
    async fetchAllEntityies(existedEntityIds = []) {
      this.entityOptionsLoading = true;
      let params = {
        populateData: true,
        page: this.entityPage,
        limit: this.entityLimit,
      };
      if (this.entitySearchString) {
        params["search_string"] = this.entitySearchString;
      }
      if (existedEntityIds && existedEntityIds.length) {
        params["existed_entity_ids"] = existedEntityIds;
      }
      await this.$store.dispatch("entities/fetchEntities", params);
      if (this.isEntitySearching || !this.entityLoadMore) {
        this.allEntities = [];
      }
      if (this.getAllEntities) {
        this.hasMoreEntities = this.getAllEntities.has_more || false;
        this.allEntities = this.removeDuplicatesByProperty(
          [...this.allEntities, ...(this.getAllEntities.data || [])],
          "_id"
        );
        this.entityOptionsLoading = false;
      } else {
        this.entityOptionsLoading = false;
      }
      this.entityLoadMore = false;
    },
    removeDuplicatesByProperty(arr, prop) {
      const uniqueSet = new Set();
      const uniqueArray = arr.filter((obj) => {
        const propertyValue = obj[prop];
        if (!uniqueSet.has(propertyValue)) {
          uniqueSet.add(propertyValue);
          return true;
        }
        return false;
      });
      return uniqueArray;
    },
    searchEntities(search_string) {
      if (search_string) {
        this.isEntitySearching = true;
        if (search_string.length > 2) {
          this.entitySearchString = search_string;
          this.entityPage = 1;
          this.fetchAllEntityies();
        }
      } else {
        this.isEntitySearching = false;
        this.entitySearchString = "";
        this.entityPage = 1;
        this.fetchAllEntityies();
      }
    },
    async onSubmit() {
      this.loading = true;
      this.loadingText = "Updating info...";
      this.companyDetails["global_filters"] = this.filters;
      if (this.companyDetails?._id) {
        await this.$store.dispatch(
          "company/updateCompany",
          this.companyDetails
        );
        if (this.getUpdateCompany.success) {
          await this.$store.dispatch(
            "company/fetchCompany",
            this.companyDetails._id
          );
          this.$notify.success({
            title: "Success",
            message: "Filters updated successfully",
          });
          window.location.reload();
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getUpdateCompany.message,
          });
        }
      }
      this.loading = false;
    },
    async getApplicationData() {
      this.loading = true;
      this.loadingText = "Fetching info...";
      // await this.$store.dispatch(
      //   "company/fetchCompany",
      //   this.getActiveWorkspace.company_id
      // );
      if (this.getCompanyDetails) {
        this.filters = this.getCompanyDetails.global_filters || [{}];
        this.companyDetails = this.getCompanyDetails;
        await Promise.all([
          this.fetchAllEntityies(
            this.filters
              .filter((e) => e.field_type == "ENTITY" && e.entity_id)
              .flatMap((e) => e.entity_id)
          ),
          this.fetchEntityData(
            this.filters
              .filter((e) => e.field_type == "ENTITY" && e.entity_id)
              .flatMap((e) => e.entity_id)
          ),
          this.fetchEntityRelatedData(
            this.filters
              .filter((e) => e.field_type == "ENTITY" && e.entity_id)
              .flatMap((e) => e.entity_id)
          ),
        ]);
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getInitialUrl",
      "getUserSignature",
      "getDefaultDateFormat",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("company", ["getCompanyDetails", "getUpdateCompany"]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityRecordsForTable",
      "getEntityFieldRelatedEntities",
    ]),
    checkDisabled() {
      let emptyFilter = this.filters.find((e) => {
        if (!e.field_type) {
          return true;
        } else if (e.field_type == "ENTITY") {
          if (!e.entity_id || !e.operator) {
            return true;
          }
        } else if (e.field_type == "DATE") {
          if (!e.filter_type || !e.value) {
            return true;
          }
        }
        return false;
      });
      return emptyFilter ? true : false;
    },
  },
  async mounted() {
    //Fetch global filters
    this.getApplicationData();
  },
};
</script>

<style scoped>
.navbar-align {
  display: flex !important;
  justify-content: space-between !important;
}
.sidemenu {
  max-height: 510px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}

.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.el-form-item {
  margin-bottom: 0px;
}

.input-container,
.app-creation,
.app-permissions {
  padding-bottom: 10px;
  border-bottom: 1px dotted #e4e7ed;
}

.input-label {
  margin-bottom: 5px;
}

.validation-error {
  color: red;
  font-size: 12px;
  margin-bottom: -10px;
}

.navigation-link {
  margin: 10px 0px;
  font-size: 12px;
  background-color: #ecf5ff;
  color: #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

a:hover {
  cursor: pointer;
}

ol {
  margin-bottom: 10px;
}

.xml-code-block {
  background-color: #f2f6fc;
  font-size: 12px;
}

.xml-code-block a {
  background-color: #ecf5ff;
  color: #409eff;
}

a {
  color: #409eff;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.breadcrumb-item {
  cursor: pointer;
  color: #409eff;
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #909399;
}

.folder-column {
  cursor: pointer;
}

.main-label {
  margin-bottom: 10px;
}
</style>
